<template>
  <div class="awards-page user-page">
    <div class="content-wrapper">
      <h2>{{ campaignName }}</h2>
    </div>
    <b-card>
      <draggable :list="tree" handle=".kpi-handle" @change="postNewPosition" ghost-class="ghost-item">
        <b-row v-for="award in tree" :key="award.id" class="align-items-center py-4 row-divider">
          <b-col lg="6">
            <div class="d-flex align-items-center">
              <img class="circle img-fit flex-shrink-0 mr-4" :src="award.url_image" />
              <div>
                <h4 class="mb-1">{{ award.title["it-IT"] }}</h4>
                <p class="pb-0 pt-2">
                  {{ cutDescrizione(award.description["it-IT"]) }}
                </p>
              </div>
            </div>
          </b-col>
          <b-col lg="2" class="text-center">
            <h1 class="mb-1">{{ award.lista_badge.length }}</h1>
            <h6 class="m-0">
              Badge{{ award.lista_badge.length != 1 ? "s" : "" }} to win
            </h6>
          </b-col>
          <b-col lg="2" class="text-center">
            <h1 class="mb-1">
              {{ calcolaPuntiDaBadge(award) + award.punti_aggiuntivi }}
            </h1>
            <h6 class="m-0">Total points needed</h6>
          </b-col>
          <b-col lg="2" class="text-center h4 text-muted">
            <b-icon icon="arrows-move" class="pointer kpi-handle mr-3"></b-icon>
            <b-icon @click="
  currentItem = award;
tmpItem = JSON.parse(JSON.stringify(award));
            " icon="gear-fill" class="pointer mr-3" v-b-toggle.sidebar-award></b-icon>
            <b-icon @click="deleteAward(award.id)" icon="trash-fill" class="pointer"></b-icon>
          </b-col>
        </b-row>
      </draggable>

      <b-button squared size="lg" class="button-dashed big-button mt-5" v-b-toggle.sidebar-award>
        <b-icon-plus />Add Award
      </b-button>
    </b-card>
    <div class="content-wrapper" v-if="hasNextRoute()">
      <b-button squared variant="primary" size="lg" @click="toNextRoute()">Save and continue</b-button>
    </div>
    <b-button v-if="isDebug" class="fixed-button py-2" v-b-toggle.sidebar-iframe>
      <img src="../../assets/images/phone.png" class="img-fluid" />
    </b-button>
    <b-sidebar @hidden="resetDataSidebarIframe" class="right-sidebar iframe-sidebar" id="sidebar-iframe" width="500px"
      right shadow lazy>
      <div class="login-as">
        <h4>Login as:</h4>
        <b-select @change="getUrlDemo" :options="usersList" v-model="userSelected">
        </b-select>
        user selectd: {{ userSelected }} || url demo: {{ url_demo }}
      </div>
      <div class="iframe-phone">
        <iframe class="iframe-phone-iframe" :src="url_demo" frameborder="0"></iframe>
      </div>
    </b-sidebar>
    <b-sidebar class="right-sidebar" id="sidebar-award" right shadow lazy @hidden="resetSidebar">
      <div class="content-wrapper my-3 mx-1">
        <div class="d-flex align-items-center mb-5">
          <div class="upload-photo-cicrle" v-bind:style="{
            backgroundImage: 'url(' + backgroundImage() + ')',
          }">
            <b-form-file class="button-file photo-button" v-model="file_photo" @input="updatePhotoURL"
              accept="image/jpeg, image/png, image/gif">
              <template #placeholder>
                <b-icon-camera-fill />
              </template>
              <template #file-name>
                <b-icon-camera-fill />
              </template>
            </b-form-file>
          </div>
          <h4 v-if="tmpItem.id" class="m-0 ml-4">Edit Award</h4>
          <h4 v-else class="m-0 ml-4">New Award</h4>
        </div>
        <b-form-group label="Title" label-for="title">
          <b-form-input v-model="tmpItem.title[title_lang]" />
          <LangOptions v-model="title_lang" :options="languages" />
        </b-form-group>
        <b-form-group label="Description" label-for="description-en">
          <b-form-textarea v-model="tmpItem.description[description_lang]" rows="3" />
          <LangOptions v-model="description_lang" :options="languages" />
        </b-form-group>
        <b-form-group class="mt-5 mb-2">
          <template #label>
            Select the badges to be earned to get the prize
            <InfoPopover class="ml-1" text="Select what badge needs to be won to unlock this award" />
          </template>
          <div class="checkbox-container">
            <b-row v-for="option in badge_list_option" :key="option.value" class="p-2 align-items-center">
              <b-col md="9">
                <b-form-checkbox v-model="tmpItem.lista_badge" :value="option.value">
                  <strong class="ml-3">
                    {{ option.text }}
                  </strong>
                </b-form-checkbox>
              </b-col>
              <b-col md="3" class="text-right">
                <span class="mr-3 text-muted">
                  {{ option.punti_per_azione }} point{{
                      option.punti_per_azione != 1 ? "s" : ""
                  }}
                </span>
              </b-col>
            </b-row>
          </div>
        </b-form-group>
        <span>To get the award you need:
          <strong>{{ tmpItem.lista_badge.length }} badge{{
              tmpItem.lista_badge.length != 1 ? "s" : ""
          }}
            = {{ badge_selezionati_punti }} point{{
                badge_selezionati_punti != 1 ? "s" : ""
            }}</strong></span>
        <div class="p-4 bg-gray mt-5">
          <b-form-checkbox v-model="tmpItem.check_punti_aggiuntivi">
            <div>
              In addition to the {{ badge_selezionati_punti }}p ({{
                  tmpItem.lista_badge.length
              }}
              badge{{ tmpItem.lista_badge.length != 1 ? "s" : "" }}) would you
              like to add additional points to get the prize?
              <!-- <InfoPopover class="ml-1" text="link ecc." /> -->
            </div>
          </b-form-checkbox>
          <template v-if="tmpItem.check_punti_aggiuntivi">
            <div class="d-flex align-items-center ml-4 mt-2">
              <b-input-group style="width: 10rem">
                <template #prepend>
                  <b-input-group-text> Points </b-input-group-text>
                </template>
                <b-form-input type="number" no-wheel v-model="tmpItem.punti_aggiuntivi"></b-form-input>
              </b-input-group>
              <strong class="ml-3">Total points:
                {{
                    badge_selezionati_punti +
                    parseInt(tmpItem.punti_aggiuntivi || 0)
                }}</strong>
            </div>
          </template>
          <b-form-checkbox v-model="tmpItem.check_max_utenti" class="mt-3">
            <div>
              Assign this award to a maximum number of users
              <!-- <InfoPopover class="ml-1" text="link ecc." /> -->
            </div>
            <div style="font-size: 12px">
              The first who reach the requirements
            </div>
          </b-form-checkbox>
          <b-input-group v-show="tmpItem.check_max_utenti" class="ml-4 mt-2" style="width: 6rem">
            <template #prepend>
              <b-input-group-text>
                <b-icon icon="people-fill" />
              </b-input-group-text>
            </template>
            <b-form-input type="number" no-wheel v-model="tmpItem.max_utenti"></b-form-input>
          </b-input-group>
          <!-- <b-form-checkbox v-model="tmpItem.abilita_feedback" class="mt-3">
            <div>
              Enable sending feedback request
              <InfoPopover class="ml-1" text="descrizione ecc." />
            </div>
          </b-form-checkbox> -->
        </div>
        <div class="mt-5 mb-5">
          <b-button v-if="isLoading" squared variant="primary" size="lg" class="big-button" disabled>
            <b-spinner small type="grow"></b-spinner> Loading...
          </b-button>
          <b-button v-else squared variant="primary" size="lg" class="big-button" @click="uploadAward">Save</b-button>
        </div>
        <div class="mt-5 mb-5"></div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import AxiosService from "../../axiosServices/AxiosService";
import draggable from "vuedraggable";

import * as CampaignRoutes from "@/utils/CampaignRoutes.js";
export default {
  components: { draggable },
  data() {
    return {
      campaignName: "",

      isDebug: null,
      idCampaign: this.$route.params.campaignId,
      badgeList: [],

      awards_list: [],
      tree: [],

      currentItem: null,
      tmpItem: {
        id: 0,
        url_image: this.$driveAddress + "Common/award.svg",
        title: {},
        description: {},
        lista_badge: [],
        check_punti_aggiuntivi: false,
        punti_aggiuntivi: 0,
        check_max_utenti: false,
        max_utenti: 0,
        abilita_feedback: false,
      },

      title_lang: "",
      description_lang: "",
      file_photo_url: "",
      file_photo: null,

      isLoading: false,

      isNewCampaign: this.$route.params.campaignId == null,

      url_demo: "",
      usersList: [{ value: null, text: "Seleziona Utente" }],
      userSelected: null,
    };
  },
  service: null,
  serviceBadges: null,
  campaignService: null,
  created() {
    this.service = new AxiosService("Adward");
    this.serviceBadges = new AxiosService("Badge");
    this.campaignService = new AxiosService("Campaign");
    this.title_lang = this.default_lang.value;
    this.description_lang = this.default_lang.value;

    // this.service.readCustomEndpoint("Setting").then((res) => {
    //   if (res) {
    //     this.url_demo = res.url_demo;
    //   } else {
    //     console.err("ERROr calling Setting");
    //   }
    // });
    this.isDebugFunction();
  },
  mounted() {
    if (!this.isNewCampaign) {
      this.getUsersByCampaign();
    }
    this.campaignService
      .readById(this.$route.params.campaignId)
      .then((data) => {
        this.campaignName = data.title[this.default_lang.value];
      });
    let params = [{ param: "idCampagna", value: this.idCampaign }];
    this.service.read(params).then((res) => {
      res.forEach((element) => {
        this.awards_list.push(element);
      });
      this.tree = this.buildTree(this.awards_list);
    });
    this.serviceBadges.read(params).then((res) => {
      res.forEach((element) => {
        this.badgeList.push(element);
      });
    });
  },
  methods: {
    getUsersByCampaign() {
      this.usersList.splice(1);
      if (this.$route.params.campaignId) {
        this.service
          .readCustomEndpoint(
            "CampaignManager/GetUsersInCampaign",
            this.$route.params.campaignId
          )
          .then((res) => {
            res.forEach((element) => {
              this.usersList.push(element);
            });
          });
      } else {
        console.log("FAIL ");
      }
    },
    getUrlDemo() {
      if (this.userSelected != null) {
        this.service.readCustomEndpoint("Setting").then((res) => {
          if (res) {
            console.log("🚀 ~ file: Awards.vue ~ line 399 ~ this.service.readCustomEndpoint ~ res", res)
            this.url_demo =
              res.url_demo +
              "/debug/" +
              this.$route.params.campaignId +
              "/" +
              this.userSelected +
              "/award/"
              + this.campaignName
              ;
          } else {
            console.err("ERROr calling Setting");
          }
        });
      } else {
        this.url_demo = "";
        // this.service.readCustomEndpoint("Setting").then((res) => {
        //   this.url_demo = res.url_demo + "login";
        // });
      }
    },
    resetDataSidebarIframe() {
      this.userSelected = null;
      this.url_demo = "";
    },
    isDebugFunction() {
      this.isDebug = null;
      this.service
        .readCustomEndpoint(
          "CampaignManager/IsDebugEnabledOnCampaign/" +
          this.$route.params.campaignId
        )
        .then((res) => {
          this.isDebug = res;
          console.log("IS DEBUG? ", this.isDebug);
        });
    },
    cutDescrizione(descrizione) {
      if (descrizione) {
        if (descrizione.lenght <= 100) return descrizione;
        return descrizione.substring(0, 100).trim() + "..";
      }
    },
    updatePhotoURL() {
      this.file_photo_url = URL.createObjectURL(this.file_photo) || "";
    },
    resetSidebar() {
      this.tmpItem = {
        id: 0,
        url_image: this.$driveAddress + "Common/award.svg",
        title: {},
        description: {},
        lista_badge: [],
        check_punti_aggiuntivi: false,
        punti_aggiuntivi: 0,
        check_max_utenti: false,
        max_utenti: 0,
        abilita_feedback: false,
      };
      this.currentItem = null;
      this.file_photo_url = "";
      this.file_photo = null;
      this.title_lang = this.default_lang.value;
      this.description_lang = this.default_lang.value;
    },
    deleteAward(id) {
      this.service
        .delete(id)
        .then((res) => {
          if (res) {
            this.awards_list = this.awards_list.filter(
              (currentItem) => id !== currentItem.id
            );
            this.tree = this.buildTree(this.awards_list);
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
    async uploadAward() {
      this.isLoading = true;
      let item = { ...this.tmpItem };

      const validationObject = this.$validate("award", item);
      if (!validationObject.validation) {
        this.isLoading = false;
        this.$errorToast(validationObject.messages.join(", "));
        return false;
      }

      let params = [{ param: "idCampagna", value: this.idCampaign }];

      if (this.file_photo) {
        const formData = new FormData();
        formData.append("pic", this.file_photo);
        let imageName = await this.service
          .uploadFile(formData)
          .then((res) => res.data);
        let imageUrl = this.$driveAddress + imageName;
        item.url_image = imageUrl.replaceAll(" ", "%20");
      }

      if (item.id) {
        this.service
          .update(item)
          .then((res) => {
            if (res.id == item.id) {
              Object.keys(this.currentItem).forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(item, element)) {
                  this.currentItem[element] = item[element];
                }
              });
              this.$root.$emit("bv::toggle::collapse", "sidebar-award");
              this.tree = this.buildTree(this.awards_list);
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.service
          .create(item, params)
          .then((res) => {
            if (res.id) {
              item.id = res.id;
              this.awards_list.push(item);
              this.$root.$emit("bv::toggle::collapse", "sidebar-award");
              this.tree = this.buildTree(this.awards_list);
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    buildTree() {
      let list = JSON.parse(JSON.stringify(this.awards_list));
      if (!list) return [];
      let map = {},
        node,
        roots = [],
        i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.id_padre && node.id_padre !== "0") {
          if (Object.prototype.hasOwnProperty.call(map, node.id_padre)) {
            list[map[node.id_padre]].children.push(node);
          }
          //else nodo orfano
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
    flattenTree(children, parent) {
      return Array.prototype.concat.apply(
        children.map((x) => {
          x.id_padre = parent || 0;
          return x;
        }),
        children.map((x) => this.flattenTree(x.children || [], x.id))
      );
    },
    backgroundImage() {
      return this.file_photo_url || this.tmpItem.url_image;
    },
    calcolaPuntiDaBadge(item) {
      return this.badge_list_option.reduce((sum, current) => {
        if (item.lista_badge.includes(current.value))
          return sum + current.punti_per_azione;
        else return sum;
      }, 0);
    },
    hasNextRoute() {
      return CampaignRoutes.hasNextRoute(this, "Awards");
    },
    toNextRoute() {
      CampaignRoutes.toNextRoute(this, "Awards");
    },
    postNewPosition() {
      let newOrder = [];
      for (let i = 0; i < this.tree.length; i++) {
        newOrder.push({ ID: this.tree[i].id, Ordine: i });
      }
      this.service
        .updateOrder(newOrder)
        .then(() => {
          this.$successToast();
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
  },
  computed: {
    badge_selezionati_punti() {
      return this.badge_list_option.reduce((sum, current) => {
        if (this.tmpItem.lista_badge.includes(current.value))
          return sum + current.punti_per_azione;
        else return sum;
      }, 0);
    },
    badge_list_option() {
      return this.badgeList.map((x) => {
        return {
          text: x.title[this.default_lang.value],
          value: x.id,
          punti_per_azione: x.punti_per_azione,
        };
      });
    },
    languages() {
      return this.$parent.companyLanguages;
    },
    default_lang() {
      return this.$parent.default_lang;
    },
  },
};
</script>